// Transactions.js

import React, { useState, useEffect } from 'react';
import api from '../../services/api'; // Adjust the import path as needed
import TickerWithLogo from '../TickerWithLogo'; // Adjust the import path as needed
import axiosInstance from '../../services/axiosInstance'; // Adjust the import path as needed
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'; // Import default styles
import './Transactions.css'; // Your custom styles

const Transactions = () => {
  // State variables
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [, setMessage] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [tickerSearch, setTickerSearch] = useState('');
  const [sortConfig, setSortConfig] = useState([]);
  const [strategy, setStrategy] = useState('');
  const [strategyNumber, setStrategyNumber] = useState('');
  const [editableStrategyId, setEditableStrategyId] = useState(null);
  const [editableStrategy, setEditableStrategy] = useState('');
  const [editableStrategyNumber, setEditableStrategyNumber] = useState('');
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  useEffect(() => {
    const fetchTrades = async () => {
      try {
        const response = await api.get('/trades');
        const transactionsData = response.data.map((transaction) => {
          let typeDisplay = '';
          switch (transaction.instrumentType) {
            case 'VANILLA':
              typeDisplay = 'Options';
              break;
            case 'COMMON_STOCK':
              typeDisplay = 'Stocks';
              break;
            case 'EXCHANGE_TRADED_FUND':
              typeDisplay = 'ETFs';
              break;
            case 'NO_LOAD_TAXABLE':
              typeDisplay = 'Mutual Funds';
              break;
            default:
              typeDisplay = transaction.instrumentType;
          }

          return {
            ...transaction,
            ticker: transaction.underlyingSymbol,
            typeDisplay,
          };
        });

        setTransactions(transactionsData);
        const sortedFilteredTransactions = applyMultiSorting(
          transactionsData,
          sortConfig
        );
        setFilteredTransactions(sortedFilteredTransactions);
      } catch (error) {
        setMessage('Failed to fetch transactions.');
      }
    };

    fetchTrades();
  }, [sortConfig]);

  const applyMultiSorting = (data, sortConfig) => {
    if (!sortConfig || !sortConfig.length) return data;

    let sortedResult = [...data];

    for (let i = 0; i < sortConfig.length; i++) {
      const { key, direction } = sortConfig[i];

      sortedResult = sortedResult.sort((a, b) => {
        let comparison = 0;

        if (key === 'fees_comm') {
          const aValue = Math.ceil((a.cost - a.netAmount) * 100) / 100;
          const bValue = Math.ceil((b.cost - b.netAmount) * 100) / 100;
          comparison = aValue - bValue;
        } else if (key === 'date') {
          const aValue = new Date(a.tradeDate);
          const bValue = new Date(b.tradeDate);
          comparison = aValue - bValue;
        } else if (key === 'quantity') {
          const aValue = parseFloat(a.amount);
          const bValue = parseFloat(b.amount);
          comparison = aValue - bValue;
        } else if (key === 'amount') {
          const aValue = parseFloat(a.netAmount);
          const bValue = parseFloat(b.netAmount);
          comparison = aValue - bValue;
        } else if (key === 'positionEffect') {
          const aPositionEffect = a.positionEffect
            ? a.positionEffect.toUpperCase()
            : null;
          const bPositionEffect = b.positionEffect
            ? b.positionEffect.toUpperCase()
            : null;

          if (aPositionEffect === null) return 1;
          if (bPositionEffect === null) return -1;

          comparison = aPositionEffect.localeCompare(bPositionEffect);
        } else if (key === 'strategy') {
          const aStrategy = a.strategy ? a.strategy.toLowerCase() : null;
          const bStrategy = b.strategy ? b.strategy.toLowerCase() : null;

          const aStrategyNumber = a.strategyNumber
            ? parseInt(a.strategyNumber)
            : Number.MAX_SAFE_INTEGER;
          const bStrategyNumber = b.strategyNumber
            ? parseInt(b.strategyNumber)
            : Number.MAX_SAFE_INTEGER;

          if (aStrategy === null) return 1;
          if (bStrategy === null) return -1;

          comparison = aStrategy.localeCompare(bStrategy);

          if (comparison === 0) {
            comparison = aStrategyNumber - bStrategyNumber;
          }
        } else if (key === 'strikePrice') {
          const aStrike =
            a.strikePrice !== null && a.strikePrice !== undefined
              ? parseFloat(a.strikePrice)
              : Number.MAX_SAFE_INTEGER;
          const bStrike =
            b.strikePrice !== null && b.strikePrice !== undefined
              ? parseFloat(b.strikePrice)
              : Number.MAX_SAFE_INTEGER;

          if (a.strikePrice === null || a.strikePrice === undefined) return 1;
          if (b.strikePrice === null || b.strikePrice === undefined) return -1;

          comparison = aStrike - bStrike;
        } else if (key === 'putCall') {
          const aPutCall = a.putCall ? a.putCall.toUpperCase() : null;
          const bPutCall = b.putCall ? b.putCall.toUpperCase() : null;

          if (aPutCall === null) return 1;
          if (bPutCall === null) return -1;

          comparison = aPutCall.localeCompare(bPutCall);
        } else if (
          typeof a[key] === 'string' &&
          typeof b[key] === 'string'
        ) {
          comparison = a[key].localeCompare(b[key]);
        } else if (
          typeof a[key] === 'number' &&
          typeof b[key] === 'number'
        ) {
          comparison = a[key] - b[key];
        } else if (a[key] instanceof Date && b[key] instanceof Date) {
          comparison = a[key] - b[key];
        } else {
          comparison = a[key] > b[key] ? 1 : -1;
        }

        return direction === 'asc' ? comparison : -comparison;
      });
    }
    return sortedResult;
  };

  const sortTransactions = (key) => {
    let newSortConfig = Array.isArray(sortConfig) ? [...sortConfig] : [];

    const existingSortIndex = newSortConfig.findIndex(
      (config) => config.key === key
    );

    if (existingSortIndex !== -1) {
      const existingSort = newSortConfig.splice(existingSortIndex, 1)[0];
      existingSort.direction =
        existingSort.direction === 'asc' ? 'desc' : 'asc';
      newSortConfig.push(existingSort);
    } else {
      newSortConfig.push({ key, direction: 'asc' });
    }

    setSortConfig(newSortConfig);
    const sortedTransactions = applyMultiSorting(
      filteredTransactions.length ? filteredTransactions : transactions,
      newSortConfig
    );
    setFilteredTransactions(sortedTransactions);
  };

  const handleCheckBoxChange = (transaction) => {
    let updatedSelectedTransactions = [...selectedTransactions];

    if (updatedSelectedTransactions.some((t) => t.id === transaction.id)) {
      updatedSelectedTransactions = updatedSelectedTransactions.filter(
        (t) => t.id !== transaction.id
      );
    } else {
      updatedSelectedTransactions.push(transaction);
    }

    setSelectedTransactions(updatedSelectedTransactions);
  };

  const handleSearchClick = () => {
    if (!fromDate || !toDate) {
      return;
    }

    const from = fromDate.split('T')[0];
    const to = toDate.split('T')[0];

    let filtered = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.tradeDate);
      const localDateString = transactionDate.toLocaleDateString('en-CA');
      return localDateString >= from && localDateString <= to;
    });

    if (tickerSearch) {
      filtered = filtered.filter((transaction) =>
        transaction.ticker.toUpperCase().includes(tickerSearch)
      );
    }

    const sortedFilteredTransactions = applyMultiSorting(filtered, sortConfig);
    setFilteredTransactions(sortedFilteredTransactions);
  };

  const handleResetClick = () => {
    setFromDate('');
    setToDate('');

    let filtered = transactions;

    if (tickerSearch) {
      filtered = filtered.filter((transaction) =>
        transaction.ticker.toUpperCase().includes(tickerSearch)
      );
    }

    const sortedFilteredTransactions = applyMultiSorting(filtered, sortConfig);
    setFilteredTransactions(sortedFilteredTransactions);
  };

  const handleTickerSearchChange = (e) => {
    const searchValue = e.target.value.toUpperCase();
    setTickerSearch(searchValue);

    let filtered = transactions.filter((transaction) =>
      transaction.ticker.toUpperCase().includes(searchValue)
    );

    if (fromDate && toDate) {
      const from = fromDate.split('T')[0];
      const to = toDate.split('T')[0];
      filtered = filtered.filter((transaction) => {
        const transactionDate = new Date(transaction.tradeDate).toLocaleDateString(
          'en-CA'
        );
        return transactionDate >= from && transactionDate <= to;
      });
    }

    const sortedFilteredTransactions = applyMultiSorting(filtered, sortConfig);

    setFilteredTransactions(sortedFilteredTransactions);
  };

  const handleSyncSchwabData = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth`;
  };

  const handleIncludeTransactions = async () => {
    try {
      if (selectedTransactions.length === 0) {
        alert('Please select at least one transaction to update.');
        return;
      }

      if (!strategy || !strategyNumber) {
        alert('Please provide both a strategy and strategy number.');
        return;
      }

      const transactionIds = selectedTransactions.map((trade) => trade.id);

      const response = await api.post('/trades/assign-strategy', {
        strategy,
        strategyNumber,
        transactionIds,
      });

      if (response.data.errors) {
        response.data.errors.forEach((error) => alert(error));
      } else {
        alert(response.data.message);

        const updatedTransactions = transactions.map((trade) => {
          if (transactionIds.includes(trade.id)) {
            return {
              ...trade,
              strategy: strategy,
              strategyNumber: strategyNumber,
            };
          }
          return trade;
        });

        setTransactions(updatedTransactions);

        let filteredTransactions = updatedTransactions;

        if (fromDate && toDate) {
          filteredTransactions = filteredTransactions.filter((transaction) => {
            const tradeDate = new Date(transaction.tradeDate);
            return tradeDate >= new Date(fromDate) && tradeDate <= new Date(toDate);
          });
        }

        if (tickerSearch) {
          filteredTransactions = filteredTransactions.filter((transaction) =>
            transaction.ticker.toUpperCase().includes(tickerSearch.toUpperCase())
          );
        }

        const sortedFilteredTransactions = applyMultiSorting(
          filteredTransactions,
          sortConfig
        );
        setFilteredTransactions(sortedFilteredTransactions);

        setSelectedTransactions([]);
      }
    } catch (error) {
      console.error('Failed to include transactions in strategy:', error);
      alert('An error occurred while including transactions in strategy.');
    }
  };

  const handleDoubleClick = (transaction) => {
    setEditableStrategyId(transaction.id);
    setEditableStrategy(transaction.strategy || '');
    setEditableStrategyNumber(transaction.strategyNumber || '');
  };

  const handleStrategySave = async (transaction) => {
    try {
      const updatedStrategy =
        editableStrategy.trim() === '' ? null : editableStrategy;
      const updatedStrategyNumber =
        editableStrategyNumber.trim() === '' ? null : editableStrategyNumber;

      if (updatedStrategy && !updatedStrategyNumber) {
        alert('A strategy number is required when selecting a strategy.');
        return;
      }

      if (!updatedStrategy && updatedStrategyNumber) {
        alert('A strategy is required when specifying a strategy number.');
        return;
      }

      await api.post('/trades/update-strategy', {
        transactionId: transaction.id,
        strategy: updatedStrategy,
        strategyNumber: updatedStrategyNumber,
      });

      const updatedTransactions = transactions.map((trade) =>
        trade.id === transaction.id
          ? {
              ...trade,
              strategy: updatedStrategy,
              strategyNumber: updatedStrategyNumber,
            }
          : trade
      );
      setTransactions(updatedTransactions);
      setEditableStrategyId(null);

      const sortedUpdatedTransactions = applyMultiSorting(
        updatedTransactions,
        sortConfig
      );
      setFilteredTransactions(sortedUpdatedTransactions);
    } catch (error) {
      alert('An error occurred while updating the strategy.');
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str
      ? str
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .split(' ')
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ')
      : '';
  };

  const handleRemoveStrategy = async () => {
    try {
      if (selectedTransactions.length === 0) {
        alert('No transactions selected.');
        return;
      }

      const transactionIds = selectedTransactions.map((trade) => trade.id);

      const response = await api.post('/trades/remove-strategy', {
        transactionIds,
      });

      if (response.data.errors) {
        response.data.errors.forEach((error) => alert(error));
      } else {
        alert('Strategy successfully removed from selected transactions!');

        const updatedTransactions = transactions.map((trade) => {
          if (transactionIds.includes(trade.id)) {
            return {
              ...trade,
              strategy: null,
              strategyNumber: null,
            };
          }
          return trade;
        });

        setTransactions(updatedTransactions);

        let filteredTransactions = updatedTransactions;

        if (fromDate && toDate) {
          filteredTransactions = filteredTransactions.filter((transaction) => {
            const tradeDate = new Date(transaction.tradeDate);
            return tradeDate >= new Date(fromDate) && tradeDate <= new Date(toDate);
          });
        }

        if (tickerSearch) {
          filteredTransactions = filteredTransactions.filter((transaction) =>
            transaction.ticker.toUpperCase().includes(tickerSearch.toUpperCase())
          );
        }

        const sortedFilteredTransactions = applyMultiSorting(
          filteredTransactions,
          sortConfig
        );
        setFilteredTransactions(sortedFilteredTransactions);

        setSelectedTransactions([]);
      }
    } catch (error) {
      console.error('Failed to remove strategy:', error);
      alert('An error occurred while removing the strategy.');
    }
  };

  const handleRefreshTransactions = async () => {
    try {
      const response = await axiosInstance.post('/schwab/refresh');
      const newTransactions = response.data;

      setTransactions((prevTransactions) => [...prevTransactions, ...newTransactions]);

      setMessage('Transactions successfully refreshed!');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setMessage('Schwab tokens expired. Please re-authenticate with Schwab.');
      } else {
        console.error('Error refreshing transactions:', error);
        setMessage('Failed to refresh transactions.');
      }
    }
  };

  // New function to check if all displayed rows are selected
  const areAllRowsSelected = () => {
    if (filteredTransactions.length === 0) {
      return false;
    }
    return filteredTransactions.every((transaction) =>
      selectedTransactions.some((t) => t.id === transaction.id)
    );
  };

  // New function to handle header checkbox change
  const handleHeaderCheckboxChange = () => {
    if (areAllRowsSelected()) {
      // Unselect all currently displayed transactions
      const updatedSelectedTransactions = selectedTransactions.filter(
        (selectedTransaction) =>
          !filteredTransactions.some(
            (transaction) => transaction.id === selectedTransaction.id
          )
      );
      setSelectedTransactions(updatedSelectedTransactions);
    } else {
      // Select all currently displayed transactions
      const newSelectedTransactions = [
        ...selectedTransactions,
        ...filteredTransactions.filter(
          (transaction) =>
            !selectedTransactions.some((t) => t.id === transaction.id)
        ),
      ];
      setSelectedTransactions(newSelectedTransactions);
    }
  };

  // Constants for table sizing
  const rowHeight = 70;
  const headerHeight = 100;
  const maxRowsToShow = 25;

  // Calculate the total width of all columns
  const totalColumnWidth =
  15 + // Select
  50 + // Logo
  70 + // Ticker
  90 + // Date
  80 + // Type
  100 + // Put/Call
  120 + // Open/Close
  100 + // Quantity
  90 + // Strike
  90 + // Price
  80 + // Fees
  120 + // Amount
  400; // Strategy

  const calculateTableHeight = () => {
    const numberOfRows = Math.min(filteredTransactions.length, maxRowsToShow);
    return headerHeight + numberOfRows * rowHeight;
  };

  // Helper function for sort indicator
  const getSortIndicator = (key) => {
    const sortObj = sortConfig.find((config) => config.key === key);
    return sortObj ? (sortObj.direction === 'asc' ? '↑' : '↓') : '';
  };

  const headerRenderer = (dataKey) => ({ label }) => (
    <div onClick={() => sortTransactions(dataKey)} className="header-cell">
      {label} {getSortIndicator(dataKey)}
    </div>
  );

  // Custom header row renderer
  const headerRowRenderer = ({ className, columns, style }) => {
    // Create the first header row
    const firstRowCells = [
      // Empty cell for the "Select" column
      <div
        key="select-header"
        className="header-cell group-header"
        style={{
          width: 15,
          minWidth: 15,
          height: headerHeight / 2,
        }}
      ></div>,
      // "Ticker" header spanning "Logo" and "Ticker" columns
      <div
        key="ticker-header"
        className="header-cell group-header"
        style={{
          width: 50 + 70, // Width of "Logo" + "Ticker" columns
          minWidth: 50 + 70,
          height: headerHeight / 2,
          textAlign: 'center',
          lineHeight: `${headerHeight / 2}px`,
        }}
      >
        Ticker
      </div>,
      // Empty cells for the rest of the columns in the first row
      ...columns.slice(3).map((column, index) => (
        <div
          key={`empty-header-${index}`}
          className="header-cell group-header"
          style={{
            width: column.props.width,
            minWidth: column.props.width,
            height: headerHeight / 2,
          }}
        ></div>
      )),
    ];

    // Create the second header row (individual headers)
    const secondRowCells = columns.map((column, index) => {
      // Adjust the "Logo" column to have an empty header
      if (index === 1) {
        return (
          <div
            key={`empty-logo-header`}
            className="header-cell"
            style={{
              width: column.props.width,
              minWidth: column.props.width,
              height: headerHeight / 2,
            }}
          ></div>
        );
      }
      // Return the original header cell for other columns
      return React.cloneElement(column, {
        key: `header-${index}`,
        style: {
          ...column.props.style,
          height: headerHeight / 2,
        },
      });
    });

    return (
      <div
        className={className}
        role="rowgroup"
        style={{
          ...style,
          height: headerHeight,
        }}
      >
        <div
          className="header-row"
          role="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: headerHeight / 2,
          }}
        >
          {firstRowCells}
        </div>
        <div
          className="header-row"
          role="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: headerHeight / 2,
          }}
        >
          {secondRowCells}
        </div>
      </div>
    );
  };


  return (
    <div className="min-h-screen">
      <div className="transactions">
        <h1 className="text-4xl font-bold text-gray-800 mb-6 mt-8">
          Transactions
        </h1>

        {/* Flexbox to split the filters and options strategy section */}
        <div className="flex flex-col md:flex-row md:space-x-8 mb-8">
          {/* Filter Section */}
          <div className="w-full md:w-1/2 bg-white p-6 rounded-lg shadow-lg mb-4 md:mb-0">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Filters</h2>

            {/* Date range filter */}
            <div className="flex flex-wrap gap-4 mb-4">
              <div className="flex items-center space-x-2">
                <label
                  htmlFor="fromDate"
                  className="text-sm font-medium text-gray-700"
                >
                  From:
                </label>
                <input
                  type="date"
                  id="fromDate"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="p-2 bg-gray-50 border border-gray-300 rounded-lg shadow-sm text-gray-800 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="flex items-center space-x-2">
                <label
                  htmlFor="toDate"
                  className="text-sm font-medium text-gray-700"
                >
                  To:
                </label>
                <input
                  type="date"
                  id="toDate"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="p-2 bg-gray-50 border border-gray-300 rounded-lg shadow-sm text-gray-800 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            {/* Buttons on a new row */}
            <div className="flex gap-4 mb-4">
              <button
                onClick={handleSearchClick}
                className="bg-blue-600 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-500 transition ease-in-out duration-150"
              >
                Search
              </button>
              <button
                onClick={handleResetClick}
                className="bg-red-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-red-500 transition ease-in-out duration-150"
              >
                Reset
              </button>
            </div>

            {/* Ticker search filter */}
            <div className="flex flex-wrap gap-4 mb-4">
              <div className="flex items-center space-x-2">
                <label
                  htmlFor="tickerSearch"
                  className="text-sm font-medium text-gray-700"
                >
                  Search Ticker:
                </label>
                <input
                  type="text"
                  id="tickerSearch"
                  value={tickerSearch}
                  onChange={handleTickerSearchChange}
                  className="p-2 w-64 bg-gray-50 border border-gray-300 rounded-md shadow-sm text-gray-800 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter Ticker Symbol"
                />
              </div>
            </div>
          </div>

          {/* Options Strategy Section */}
          <div className="w-full md:w-1/2 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">
              Update Transactions for Options Strategy
            </h2>

            {/* Strategy dropdown */}
            <div className="mb-4">
              <label
                htmlFor="strategy"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Strategy:
              </label>
              <select
                id="strategy"
                value={strategy}
                onChange={(e) => setStrategy(e.target.value)}
                className="p-2 w-full bg-gray-50 border border-gray-300 rounded-md shadow-sm text-gray-800 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select Strategy</option>
                <option value="longCall">Long Call</option>
                <option value="longPut">Long Put</option>
                <option value="ironCondor">Iron Condor</option>
                <option value="coveredCall">Covered Call</option>
                <option value="straddle">Straddle</option>
                <option value="strangle">Strangle</option>
                <option value="wheeling">Wheeling</option>
                <option value="cashSecuredPut">Cash Secured Put</option>
                <option value="calendarSpread">Calendar Spread</option>
              </select>
            </div>

            {/* Strategy number input */}
            <div className="mb-4">
              <label
                htmlFor="strategyNumber"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Strategy Number - Assign a unique number to differentiate between
                identical strategies for the same ticker:
              </label>
              <input
                type="text"
                id="strategyNumber"
                value={strategyNumber}
                onChange={(e) => setStrategyNumber(e.target.value)}
                className="p-2 w-full bg-gray-50 border border-gray-300 rounded-md shadow-sm text-gray-800 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter Strategy Number"
              />
            </div>

            {/* Buttons */}
            <div className="flex space-x-4">
              <button
                onClick={handleIncludeTransactions}
                className="bg-green-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-green-500 transition ease-in-out duration-150"
              >
                Update Transactions
              </button>
              <button
                onClick={handleRemoveStrategy}
                className="bg-red-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-red-500 transition ease-in-out duration-150"
              >
                Remove Strategy
              </button>
            </div>
          </div>
        </div>

        {/* Buttons for Schwab Sync and Refresh */}
        <div className="mt-8 flex flex-wrap space-x-4">
          <button
            onClick={handleSyncSchwabData}
            className="px-6 py-3 bg-blue-600 text-white font-bold rounded-md shadow-md hover:bg-blue-700 transition duration-200"
          >
            Sync Schwab Live Data
          </button>

          <button
            onClick={handleRefreshTransactions}
            className="px-6 py-3 bg-green-600 text-white font-bold rounded-md shadow-md hover:bg-green-700 transition duration-200"
          >
            Refresh
          </button>
        </div>

        {/* Render the Table */}
        <div className="table-container">
          <AutoSizer disableHeight>
            {({ width }) => (
              <Table
                width={Math.max(width, totalColumnWidth)}
                height={calculateTableHeight()}
                headerHeight={headerHeight}
                headerRowRenderer={headerRowRenderer}
                rowHeight={rowHeight} // Adjust based on your row content
                rowCount={filteredTransactions.length}
                rowGetter={({ index }) => filteredTransactions[index]}
                rowClassName={({ index }) =>
                  index % 2 === 0 ? 'evenRow' : 'oddRow'
                }
                // Enable horizontal scrolling
                className="react-virtualized-table"
                // Adjust the width of the table to accommodate all columns
                // Here, we set the table width to the sum of all column widths
                // Alternatively, you can set a fixed width or calculate dynamically
                gridStyle={{
                  overflowX: 'auto',
                }}
              >
                {/* Define your columns here with minWidth and flexGrow */}
                {/* Select Checkbox */}
                <Column
                  label=""
                  dataKey="select"
                  width={15}
                  minWidth={15}
                  headerRenderer={() => (
                    <input
                      type="checkbox"
                      id="header-checkbox"
                      className="modern-checkbox"
                      checked={areAllRowsSelected()}
                      onChange={handleHeaderCheckboxChange}
                    />
                  )}
                  cellRenderer={({ rowData }) => (
                    <input
                      type="checkbox"
                      id={`checkbox-${rowData.id}`}
                      className="modern-checkbox"
                      checked={selectedTransactions.some(
                        (t) => t.id === rowData.id
                      )}
                      onChange={() => handleCheckBoxChange(rowData)}
                    />
                  )}
                  cellClassName="center-align"
                  headerClassName="center-align"
                />

                {/* Logo */}
                <Column
                  label=""
                  dataKey="logo"
                  width={50}
                  minWidth={50}
                  cellRenderer={({ rowData }) => (
                    <TickerWithLogo ticker={rowData.ticker} />
                  )}
                  cellClassName="center-align"
                  headerClassName="center-align"
                />

                {/* Ticker */}
                <Column
                  label="Ticker"
                  dataKey="ticker"
                  width={70}
                  minWidth={70}
                  cellRenderer={({ rowData }) => rowData.ticker}
                  cellClassName="center-align"
                  headerClassName="center-align"
                />

                {/* Date */}
                <Column
                  label="Date"
                  dataKey="tradeDate"
                  width={90}
                  minWidth={90}
                  headerRenderer={headerRenderer('date')}
                  cellRenderer={({ rowData }) =>
                    new Date(rowData.tradeDate).toLocaleDateString()
                  }
                  cellClassName="center-align"
                  headerClassName="center-align"
                />

                {/* Type */}
                <Column
                  label="Type"
                  dataKey="typeDisplay"
                  width={80}
                  minWidth={80}
                  headerRenderer={headerRenderer('typeDisplay')}
                  cellRenderer={({ rowData }) => rowData.typeDisplay}
                  cellClassName="center-align"
                  headerClassName="center-align"
                />

                {/* Put/Call */}
                <Column
                  label="Put/Call"
                  dataKey="putCall"
                  width={100}
                  minWidth={100}
                  headerRenderer={headerRenderer('putCall')}
                  cellRenderer={({ rowData }) => rowData.putCall || 'N/A'}
                  cellClassName="center-align"
                  headerClassName="center-align"
                />

                {/* Open/Close */}
                <Column
                  label="Open/Close"
                  dataKey="positionEffect"
                  width={120}
                  minWidth={120}
                  headerRenderer={headerRenderer('positionEffect')}
                  cellRenderer={({ rowData }) =>
                    rowData.positionEffect || 'N/A'
                  }
                  cellClassName="center-align"
                  headerClassName="center-align"
                />

                {/* Quantity */}
                <Column
                  label="Quantity"
                  dataKey="amount"
                  width={100}
                  minWidth={100}
                  headerRenderer={headerRenderer('quantity')}
                  cellRenderer={({ rowData }) => rowData.amount}
                  cellClassName="left-align"
                  headerClassName="left-align"
                />

                {/* Strike */}
                <Column
                  label="Strike"
                  dataKey="strikePrice"
                  width={90}
                  minWidth={90}
                  headerRenderer={headerRenderer('strikePrice')}
                  cellRenderer={({ rowData }) =>
                    new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(
                      Math.ceil((rowData.strikePrice) * 100) / 100
                    ) || 'N/A'
                  }
                  cellClassName="left-align"
                  headerClassName="left-align"
                />

                {/* Price */}
                <Column
                  label="Price"
                  dataKey="price"
                  width={90}
                  minWidth={90}
                  headerRenderer={headerRenderer('price')}
                  cellRenderer={({ rowData }) =>
                    rowData.price
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(rowData.price.toFixed(2))
                      : 'N/A'
                  }
                  cellClassName="left-align"
                  headerClassName="left-align"
                />

                {/* Fees */}
                <Column
                  label="Fees"
                  dataKey="fees"
                  width={80}
                  minWidth={80}
                  headerRenderer={headerRenderer('fees_comm')}
                  cellRenderer={({ rowData }) =>
                    new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(
                      Math.ceil((rowData.cost - rowData.netAmount) * 100) / 100
                    )
                  }
                  cellClassName="left-align"
                  headerClassName="left-align"
                />

                {/* Amount */}
                <Column
                  label="Amount"
                  dataKey="netAmount"
                  width={120}
                  minWidth={120}
                  headerRenderer={headerRenderer('amount')}
                  cellRenderer={({ rowData }) =>
                    new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(rowData.netAmount)
                  }
                  cellClassName="left-align"
                  headerClassName="left-align"
                />

                {/* Strategy with Inline Editing */}
                <Column
                  label="Strategy"
                  dataKey="strategy"
                  width={400}
                  minWidth={400}
                  headerRenderer={headerRenderer('strategy')}
                  cellClassName="left-align"
                  headerClassName="left-align"
                  cellRenderer={({ rowData }) =>
                    editableStrategyId === rowData.id ? (
                      <div className="flex items-center">
                        {/* Input for Strategy */}
                        <select
                          value={editableStrategy}
                          onChange={(e) => setEditableStrategy(e.target.value)}
                          className="p-2 h-10 bg-white border border-gray-300 rounded-lg shadow-sm text-gray-800 focus:ring-blue-500 focus:border-blue-500"
                        >
                          <option value="">Select Strategy</option>
                          <option value="longCall">Long Call</option>
                          <option value="longPut">Long Put</option>
                          <option value="coveredCall">Covered Call</option>
                          <option value="wheeling">Wheeling</option>
                          <option value="cashSecuredPut">Cash Secured Put</option>
                        </select>
                        {/* Input for Strategy Number */}
                        <input
                          type="text"
                          value={editableStrategyNumber}
                          onChange={(e) =>
                            setEditableStrategyNumber(e.target.value)
                          }
                          className="ml-2 p-2 h-10 bg-white border border-gray-300 rounded-lg shadow-sm text-gray-800 focus:ring-blue-500 focus:border-blue-500 w-16"
                          placeholder="#"
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleStrategySave(rowData);
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        onDoubleClick={() => handleDoubleClick(rowData)}
                        className="cursor-pointer"
                      >
                        {(rowData.strategy
                          ? capitalizeFirstLetter(rowData.strategy)
                          : 'N/A') +
                          (rowData.strategyNumber
                            ? ` ${rowData.strategyNumber}`
                            : '')}
                      </div>
                    )
                  }
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
